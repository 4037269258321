export default {
	discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
	lookUpSpreadsheetId: "1hMKADFhGiO7lOZ60Xgy3AkLDeL8s7i364y3QgMapdSA",
	fabLabsheetId: "870270855",
	saveOutputspreadsheetId: "1MAWhnVq05eeo-NSlS4o1swTUpnduFyPEMgn726Gr6Y4",
	saveOutputsheetId: "1285439780",
	client : "4b6e6f016f8ac153825004d86951ddfcc7a624e0",
	secret : "Xbciby55FX/LgLxobkTj9KgKpsM9yCq03VvlMHU/fnS4uwnXMK2M8zPWQb2KN1BgqQm0WnJVNjhbZKragiARHHaozdGFh/xeM7/unXxM/stX53pN40i2JPMcxera8feZ",
	access : "a0a4f973cb8e8646aca7f4fd46f6e903",
	
	ApiLibraryUrl : "https://dev-my.fyles.link/api/v1/",
	ApiUsername : "JsonUser",
	ApiPassword : "w$6%2jq)Mj1123",
	
	defaultLayoutFacility : "A2130BEB-D932-4587-B8F3-57A667EA814C",
	ApiUrlTranslation : "https://dev.humusha.xyz/api/humusha",
	ApiKeyTranslation : "35Wp$-b35I-3VC&H-y8N5-p25r4",
	
	/* ImageApiUrl : "https://myfyles.site/api/v1/files/images",
	VideoApiUrl : "https://myfyles.site/api/v1/files/videos",
	VideoTagApiUrl : "https://myfyles.site/api/v1/tags/video",
	VideoApiKey : "53b00f95d399e6d721ccff34becc4665e9d8116d16b88dd54b2f98500ff1b3ea",
	VideoCategory : "668fe746ab0e502b0684a474",
    VideoTheme : "6686812dab0e502b068493d4", */
	ImageApiUrl : "https://myfyles.site/api/v1/files/images",
	//ImageApiUrl : "https://my.fyles.link/api/v1/files/images",
	
	VideoApiUrl : " https://my.fyles.link/api/v1/files/videos",
	VideoTagApiUrl : "https://my.fyles.link/api/v1/tags/video",
	VideoApiKey : "e4e9e5b64e84c51494101e2ea68008fe8d9918d403ca952bb0812b9e8792ada6",
	VideoCategory : "66605d44cd8bfc39db0855f8",
    VideoTheme : "66ed64fde9584d58792c14d0",
	
	workflowApi : "https://api.myworkflows.dev/",
	
	mongoApiUrl : "https://api.formdata.pro/",
	// mongoApiUrl : "https://dev-api.formdata.pro/",
}; 
